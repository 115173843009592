import React from "react";
import "./Footer.css";
function Footer(props) {
  return (
    <div className="footer__root">
      <p>Build with 💚 by Akshay Chavan</p>
    </div>
  );
}

export default Footer;
